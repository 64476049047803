@font-face {
  font-family: "MadeOuterRegular";
  src: local("MadeOuterRegular"), local("MadeOuterRegular"),
    url("../assets/fonts/made_outer_sans/MADE\ Outer\ Sans\ Regular\ PERSONAL\ USE.otf")
      format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "MadeOuterMedium";
  src: local("MadeOuterMedium"), local("MadeOuterMedium"),
    url("../assets/fonts/made_outer_sans/MADE\ Outer\ Sans\ Medium\ PERSONAL\ USE.otf")
      format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "RaitorRegular";
  src: local("RaitorRegular"), local("RaitorRegular"),
    url("../assets/fonts/raitor-futuristic-display-fonts/Raitor-Regular.otf")
      format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "ModellicaMedium2";
  src: local("ModellicaMedium2"), local("Modellica-Medium2"),
    url("../assets/fonts/BWModelica/BwModelicaSS02-Medium.otf")
      format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


span{
  font-family: "ModellicaMedium2", sans-serif;
}


html{
  background-color: #000;

}

body {
  position: relative;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-color: #e3e3e3 transparent;
  scrollbar-width: thin;
  -webkit-text-size-adjust: 100%;
  background-color: #000;
  background-image: linear-gradient(to right, #00000044, #00000044),
    url("../assets/images/damini-gif-bg.gif");
  background-attachment: fixed;
}

button, button span{
  font-family: "MadeOuterMedium", sans-serif;
}


h5{
  font-family: "MadeOuterRegular", sans-serif;
}
h4, a{
  font-family: "RaitorRegular", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


* {
  font-variant-numeric: normal;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-color: #e3e3e3 transparent;
  scrollbar-width: thin;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}
::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: #2f2f2f;
}
::-webkit-scrollbar-thumb:active {
  background: #2f2f2f;
}
::-webkit-scrollbar-track {
  background: #100f0f;
}
::-webkit-scrollbar-track:hover {
  background: #100f0f;
}
::-webkit-scrollbar-track:active {
  background: #100f0f;
}
::-webkit-scrollbar-corner {
  background: #100f0f;
}
